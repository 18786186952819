// to be compmleted

import {
    handleQueryResolve
} from '../utils'

export default function (mdLastName, mdFirstName, startDate, endDate) {
  const where = []
  const attrs = []
  if (mdLastName) {
    // attrs = [{
    //   param: 'luCode',
    //   type: 'sql.Int',
    //   value: luCode,
    // }]

    // where = ['CODE = @luCode']
    where.push(`doctor.LastName LIKE '%${mdLastName}%'`)
  }
  if (mdFirstName) {
    // attrs.push({
    //   param: 'DIN',
    //   type: 'sql.Int',
    //   value: DIN, // to support like
    // })

    where.push(`doctor.FirstName LIKE '%${mdFirstName}%'`)
  }
  if (startDate) {
    // where.push(`Rx.RxDate > '${startDate}'`)
  }
  if (endDate) {
    // where.push(`Rx.RxDate < '${endDate}'`)
  }
  const query = `
  SELECT
  doctor.FirstName as mdFirstName,
  doctor.LastName as mdLastName,
  doctor.ID as CPSO
  FROM
  Fillware.dbo.Doctor as doctor
  WHERE  ${where.join(' AND ')}
        `
  console.log(where)
  console.log(query)

  return this.query(query, attrs).then(handleQueryResolve)
}
